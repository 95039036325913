import { createContext, useReducer } from "react";
import alertReducer from "./AlertReducer";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  // Initial state - Reducer
  const initialState = null;

  const [state, dispatch] = useReducer(alertReducer, initialState);

  // Set alert
  const setAlert = (msg, type) => {
    dispatch({ type: "SET_ALERT", payload: { msg, type } });
    /* After 3 seconds alert will be removed */
    setTimeout(() => {
      dispatch({ type: "REMOVE_ALERT" });
    }, 3000);
  };

  return <AlertContext.Provider value={{ alert: state, setAlert }}>{children}</AlertContext.Provider>;
};

export default AlertContext;
